/**
 * Provides a date picker for Final Forms (using https://github.com/airbnb/react-dates)
 *
 * NOTE: If you are using this component inside BookingDatesForm,
 * you should convert value.date to start date and end date before submitting it to API
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import { useConfiguration } from '../../context/configurationContext';
import { ValidationError } from '../../components';

import DateInput from './DateInput';
import css from './FieldDateInput.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const handleChange = (parentOnChange, inputOnChange) => value => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (parentOnChange) {
    parentOnChange(value);
  }
  // Notify Final Form that the input has changed.
  inputOnChange(value);
};

class FieldDateInputComponent extends Component {
  render() {
    const {
      className,
      rootClassName,
      id,
      label,
      showLabelAsDisabled,
      input,
      meta,
      useMobileMargins,
      showErrorMessage,
      onChange: parentOnChange,
      placeholderText,
      busyAuthor,
      ...rest
    } = this.props;

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { touched, invalid, error } = meta;
    const value = input.value;

    // If startDate is valid label changes color and bottom border changes color too
    const dateIsValid = value && value.date instanceof Date;
    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = touched && invalid && error;

    const inputClasses = classNames({
      [css.pickerSuccess]: dateIsValid,
      [css.pickerError]: hasError,
    });

    const { onBlur, onFocus, onChange: inputOnChange, type, checked, ...restOfInput } = input;
    const inputProps = {
      onBlur: input.onBlur,
      onFocus: input.onFocus,
      onChange: handleChange(parentOnChange, inputOnChange),
      useMobileMargins,
      id,
      readOnly: typeof window !== 'undefined' && window.innerWidth < MAX_MOBILE_SCREEN_WIDTH,
      placeholderText: placeholderText,
      busyAuthor,
      ...restOfInput,
      ...rest,
    };
    const classes = classNames(rootClassName || css.fieldRoot, className);
    const errorClasses = classNames({ [css.mobileMargins]: useMobileMargins });

    return (
      <div className={classes}>
        {label ? (
          <label
            className={classNames({
              [css.mobileMargins]: useMobileMargins,
              [css.labelDisabled]: showLabelAsDisabled,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        ) : null}
        <DateInput className={inputClasses} {...inputProps} />
        {showErrorMessage ? <ValidationError className={errorClasses} fieldMeta={meta} /> : null}
      </div>
    );
  }
}

FieldDateInputComponent.defaultProps = {
  className: null,
  rootClassName: null,
  useMobileMargins: false,
  showErrorMessage: true,
  id: null,
  label: null,
  showLabelAsDisabled: false,
  placeholderText: null,
  onChange: null,
  busyAuthor: false,
};

FieldDateInputComponent.propTypes = {
  className: string,
  rootClassName: string,
  useMobileMargins: bool,
  showErrorMessage: bool,
  id: string,
  label: string,
  showLabelAsDisabled: bool,
  placeholderText: string,
  input: object.isRequired,
  meta: object.isRequired,
  onChange: func,
  busyAuthor: bool,
};

const FieldDateInput = props => {
  const config = useConfiguration();
  const {
    isOutsideRange,
    firstDayOfWeek,
    busyAuthor,
    sevenDayCut,
    fourteenDayCut,
    ...rest
  } = props;

  const isDayBlocked = day => {
    const today = moment().startOf('day');
    const twoDaysLater = today
      .clone()
      .add(2, 'days')
      .endOf('day');
    const fourteenDaysLater = today
      .clone()
      .add(14, 'days')
      .startOf('day'); // Define 14 days from today
    const november9 = moment('2024-11-09').startOf('day');
    const november17 = moment('2024-11-17').endOf('day');

    if (busyAuthor && day.isSameOrBefore(twoDaysLater)) {
      return true;
    }

    if (sevenDayCut && day.isBetween(november9, november17, null, '[]')) {
      return true;
    }

    // Block all dates 14 days from today onwards if fourteenDayCut is true
    if (fourteenDayCut && day.isSameOrBefore(fourteenDaysLater)) {
      return true;
    }

    return false;
  };

  const isOutsideRangeWithSlash = day => {
    const today = moment().startOf('day');
    const twoDaysLater = today
      .clone()
      .add(2, 'days')
      .endOf('day');
    const fourteenDaysLater = today
      .clone()
      .add(14, 'days')
      .startOf('day'); // Define 14 days from today
    const november9 = moment('2024-11-09').startOf('day');
    const november17 = moment('2024-11-17').endOf('day');

    if (busyAuthor && day.isSameOrBefore(twoDaysLater) && day.isSameOrAfter(today)) {
      return false; // Allow selection but will be marked with a slash
    }

    if (sevenDayCut && day.isBetween(november9, november17, null, '[]')) {
      return true;
    }

    if (fourteenDayCut && day.isSameOrBefore(fourteenDaysLater) && day.isSameOrAfter(today)) {
      return true;
    }
    // Disable all past dates
    if (day.isBefore(today)) {
      return true;
    }

    return isOutsideRange ? isOutsideRange(day) : defaultIsOutSideRange(day);
  };

  const defaultIsOutSideRange = day => {
    const endOfRange = 365;
    return !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'));
  };

  const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;

  const renderDayContents = day => {
    const dayMoment = moment(day);
    const today = moment().startOf('day');
    const twoDaysLater = today
      .clone()
      .add(2, 'days')
      .endOf('day');
    const fourteenDaysLater = today
      .clone()
      .add(14, 'days')
      .startOf('day');
    const november9 = moment('2024-11-09').startOf('day');
    const november17 = moment('2024-11-17').endOf('day');

    if (busyAuthor && dayMoment.isSameOrAfter(today) && dayMoment.isSameOrBefore(twoDaysLater)) {
      return (
        <div className={css.dayWithSlash}>
          <span>{dayMoment.format('D')}</span>
          <div className={css.slash}></div>
        </div>
      );
    }

    if (sevenDayCut && dayMoment.isBetween(november9, november17, null, '[]')) {
      return (
        <div className={css.dayWithSlash}>
          <span>{dayMoment.format('D')}</span>
          <div className={css.slash}></div>
        </div>
      );
    }

    if (
      fourteenDayCut &&
      dayMoment.isSameOrAfter(today) &&
      dayMoment.isSameOrBefore(fourteenDaysLater)
    ) {
      return (
        <div className={css.dayWithSlash}>
          <span>{dayMoment.format('D')}</span>
          <div className={css.slash}></div>
        </div>
      );
    }

    return <span>{dayMoment.format('D')}</span>;
  };

  return (
    <Field
      component={FieldDateInputComponent}
      isOutsideRange={isOutsideRangeWithSlash}
      isDayBlocked={isDayBlocked}
      firstDayOfWeek={firstDayOfWeek || defaultFirstDayOfWeek}
      renderDayContents={renderDayContents}
      busyAuthor={busyAuthor}
      {...rest}
    />
  );
};

// const FieldDateInput = props => {
//   const config = useConfiguration();
//   const { isOutsideRange, firstDayOfWeek, busyAuthor, ...rest } = props;

//   const isDayBlocked = day => {
//     if (busyAuthor) {
//       const today = moment().startOf('day');
//       const twoDaysLater = today
//         .clone()
//         .add(2, 'days')
//         .endOf('day');
//       return day.isSameOrBefore(twoDaysLater);
//     }
//     return false;
//   };

//   const isOutsideRangeWithSlash = day => {
//     const today = moment().startOf('day');
//     const twoDaysLater = today
//       .clone()
//       .add(2, 'days')
//       .endOf('day');

//     if (busyAuthor && day.isSameOrBefore(twoDaysLater) && day.isSameOrAfter(today)) {
//       return false; // Allow selection but will be marked with slash
//     }

//     // Disable all past dates
//     if (day.isBefore(today)) {
//       return true;
//     }

//     // Use the provided isOutsideRange or the default one
//     return isOutsideRange ? isOutsideRange(day) : defaultIsOutSideRange(day);
//   };

//   const defaultIsOutSideRange = day => {
//     const endOfRange = 365;
//     return !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'));
//   };

//   const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;

//   const renderDayContents = day => {
//     const dayMoment = moment(day);
//     const today = moment().startOf('day');
//     const twoDaysLater = today
//       .clone()
//       .add(2, 'days')
//       .endOf('day');

//     if (busyAuthor && dayMoment.isSameOrAfter(today) && dayMoment.isSameOrBefore(twoDaysLater)) {
//       return (
//         <div className={css.dayWithSlash}>
//           <span>{dayMoment.format('D')}</span>
//           <div className={css.slash}></div>
//         </div>
//       );
//     }
//     return <span>{dayMoment.format('D')}</span>;
//   };

//   return (
//     <Field
//       component={FieldDateInputComponent}
//       isOutsideRange={isOutsideRangeWithSlash}
//       isDayBlocked={isDayBlocked}
//       firstDayOfWeek={firstDayOfWeek || defaultFirstDayOfWeek}
//       renderDayContents={renderDayContents}
//       busyAuthor={busyAuthor}
//       {...rest}
//     />
//   );
// };

// const FieldDateInput = props => {
//   const config = useConfiguration();
//   const { isOutsideRange, firstDayOfWeek, busyAuthor, ...rest } = props;

//   const isDayBlocked = day => {
//     // We're not actually blocking days, just marking them
//     return false;
//   };

//   // Outside range -><- today ... today+available days -1 -><- outside range
//   // const defaultIsOutSideRange = day => {
//   //   // const endOfRange = config.stripe?.dayCountAvailableForBooking - 1;
//   //   const endOfRange = 365;
//   //   return (
//   //     !isInclusivelyAfterDay(day, moment()) ||
//   //     !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
//   //   );
//   // };
//   const defaultIsOutSideRange = day => {
//     const endOfRange = 365;
//     return (
//       !isInclusivelyAfterDay(day, moment()) ||
//       !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
//     );
//   };

//   const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;
//   return (
//     <Field
//       component={FieldDateInputComponent}
//       isOutsideRange={isOutsideRange || defaultIsOutSideRange}
//       isDayBlocked={isDayBlocked}
//       firstDayOfWeek={firstDayOfWeek || defaultFirstDayOfWeek}
//       busyAuthor={busyAuthor}
//       {...rest}
//     />
//   );
// };

export { DateInput };
export default FieldDateInput;
